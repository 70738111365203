<template>
  <div class="dashboard">
    <Loader v-if="performingRequest && (!usersByProximity || usersByProximity.length == 0)" />
    <div class="dashboard__container pb-0">
      <div class="dashboard__container--header flex justify-space-between align-center">
        <h1>Users</h1>
        <!-- <router-link :to="{name: 'employees'}">
          <button class="btn btn__small btn__outlined">Show Employees</button>
        </router-link> -->
      </div>
<!--       <div>Lat: {{lat}}</div>
      <div>Lng: {{lng}}</div> -->


      <ais-instant-search :search-client="searchClient" index-name="a_users" >
      <ais-search-box placeholder="Search Users..." />
      <ais-state-results>
        <template slot-scope="{ state: { query } }">
          <ais-hits v-show="query.length > 0">
            <template v-slot:item="{ item }">
              <div>
                <router-link :to="`users/` + item.objectID">
                  <button class="btn btn__icon btn__flat mr-4"><i class="fad fa-external-link"></i></button>
                </router-link>
                <div style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address">{{item.address.city}}, {{item.address.state}} | </span>{{item.email}} | {{item.phone}}</div>
              </div>
            </template>
          </ais-hits>
        </template>
      </ais-state-results>
    </ais-instant-search>
    <hr>
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col" style="width:100%;">
        <div class="whiteBack">
          <label for="address" style="display: block;">Search Users by Proximity:</label>
          <label>
<!--             <gmap-autocomplete class="v-input v-input__control outline-fix mt-2" >
                    <template v-slot:input="slotProps">
                        <v-text-field outlined
                                      prepend-inner-icon="place"
                                      placeholder="Location Of Event"
                                      ref="autocomplete"
                                      @place_changed="setPlace(value)">
                        </v-text-field>
                    </template>
        </gmap-autocomplete>
 -->
            <gmap-autocomplete
            id="address"
            style="width: calc(100% - 88px);"
              class="v-input v-input__control outline-fix mt-2"
               placeholder="Location" ref="autocomplete" @place_changed="setPlace">
            </gmap-autocomplete>
          </label>
          <button class="btn btn__dark btn__small ml-3" @click="clearPlace()" v-if="lat && lng">Clear</button>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard__container pt-0 pb-0" v-if="lat && lng">
    <div class="dashboard__container--body pt-0 pb-0">
      <div class="dashboard__container--body--col mb-2">
        <div class="slidecontainer whiteBack">
          <div>{{distance}} Miles</div>
          <input type="range" min="1" max="500"  v-model="distance" class="slider" id="myRange" @change="updateDistance()">
        </div>
      </div>
      <div class="dashboard__container--body--col mb-2">
        <div class="slidecontainer whiteBack">
          <div class="mb-3">
            <label for="category">Filter By Skills:</label>
            <v-select
              id="category"
              class="mt-2"
              label="title" 
              :options="skills"
              v-model="filteredSkills"
              :clearable="true"
               multiple
               @input="updateSkills()"
              >
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard__container pt-0 pb-2" v-if="lat && lng">
    <div class="caption ml-3">Results: {{filteredResults.length}}</div>
  </div>

  <div class="dashboard__container pt-0" v-if="filteredResults && filteredResults.length >= 1">
    <div class="dashboard__container--body">

      <UserTable :groupUsers="filteredResults" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #00C897;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #00C897;
  cursor: pointer;
}
</style>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
// import EmployeeStatus from '@/components/EmployeeStatus.vue'
// import ContractorStatus from '@/components/ContractorStatus.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'
// import StarRating from 'vue-star-rating'
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css'

export default {
  name: 'users',
  data() {
    return {
      batch: [],
      newGroup: null,
      showTextBox: false,
      showGroupBox: false,
      textMessage: '',
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
      isShowDistanceFilter: false,
      distance: '100',
      filteredSkills: [],
      searchClient: algoliasearch(
        '0T1SIY6Y1V',
        'f03dc899fbdd294d6797791724cdb402'
      ),
      place: '',
      lat: '',
      lng: '',
    //   columns: [
    //   {
    //     field: 'photoUrl',
    //     sortable: false,
    //     width:'42px',
    //     tdClass: 'text-center',
    //   },
    //   {
    //       label: 'Hide',
    //       field: 'delete',
    //       sortable: false,
    //       width: '80px'
    //     },
    //   {
    //     label: 'Name',
    //     field: 'fullName',
    //     sortable: false,
    //     width:'200px',
    //   },
    //   {
    //     label: 'Onboarding',
    //     field: 'onboardFlow',
    //     sortable: false,
    //     width:'100px',
    //   },
    //   {
    //     label: 'Payroll',
    //     field: 'onboardStatus',
    //     sortable: false,
    //     width:'120px',
    //   },
    //   {
    //     label: 'City',
    //     field: 'city',
    //     sortable: false,
    //     width:'100px',
    //   },
    //   {
    //     label: 'State',
    //     field: 'state',
    //     sortable: false,
    //     width:'40px',
    //   },
    //   {
    //     label: 'Joined',
    //     field: 'created',
    //     sortable: false,
    //     width:'80px',
    //   },
    //   {
    //     label: 'Phone',
    //     field: 'phone',
    //     sortable: false,
    //     width:'120px',
    //   },
    //   {
    //     label: 'Status',
    //     field: 'employmentStatus',
    //     width:'80px',
    //   },
    //   {
    //     label: 'Check',
    //     field: 'check',
    //     sortable: false,
    //     width:'64px',
    //   },
    //   {
    //     label: 'Drug',
    //     field: 'drug',
    //     sortable: false,
    //     width:'64px',
    //   },
    //   {
    //     label: 'Skills',
    //     field: 'skills',
    //     sortable: false,
    //     width:'40px',
    //   },
    //   {
    //     label: 'DNR',
    //     field: 'blacklist',
    //     width: '40px',
    //     sortable: false,
    //   },
    //   // {
    //   //   label: 'Action',
    //   //   field: 'action',
    //   //   sortable: false,
    //   //   width:'80px',
    //   // }
    // ],
    };
  },

  computed: {
    ...mapState(['employees', 'currentUser', 'userProfile', 'usersByProximity', 'skills', 'groups']),
    filteredResults() {
      if (!this.filteredSkills || this.filteredSkills.length == 0) {
        return this.usersByProximity
      } else if (this.filteredSkills && this.filteredSkills.length >= 1) {

        return this.validUsers.filter(user => this.filteredSkills.some(
        skill => user.skills.some(({title}) => (title === skill.title))));

      } else {
        return []
      }
    },
    validUsers() {
      return this.usersByProximity.filter(user => {
        return ((Array.isArray(user.skills)))
      })
    }
  },
  components: {
    Loader,
    // StarRating,
    // ContractorStatus,
    // EmployeeStatus,
    UserTable
  },
  created () {
    this.$store.dispatch("getSkills")
    this.$store.dispatch("getGroups")
  },
  methods: {
    mySearchFn(row, col, cellValue, searchTerm) {
      if (searchTerm === "") return true;
      return row.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    }, 
    showGroup() {
      this.showGroupBox = true
    },
    hideGroup() {
      this.showGroupBox = false
    },
    showBox() {
      this.showTextBox = true
    },
    hideBox() {
      this.showTextBox = false
    },
    async addToGroup() {
      this.performingRequest3 = true
      let group = this.newGroup
      await this.batch.forEach(user => {
        this.$store.dispatch("addUserToGroup", {
          group: group,
          user: user
        })
      })
      this.performingRequest3 = false
      this.newGroup = null
      this.showGroupBox = false
    },
    async sendTextMessage () {
      this.performingRequest3 = true
      let message = this.textMessage
      await this.batch.forEach(user => {
        console.log(user)
        if (user.phone) {
          let newMessage = {
            userId: user.id,
            phone: user.phone,
            message: message,
          }
          console.log(newMessage)
          this.$store.dispatch('sendUserMessage', newMessage)
        } 
      })
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    showDistanceFilter() {
      this.isShowDistanceFilter = true
    },
    hideDistanceFilter() {
      this.isShowDistanceFilter = false
    },
    removeEntry(index) {
      this.usersByProximity.splice(index, 1)
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    clearPlace() {
      this.$store.dispatch("clearUsersByProximity")
      this.currentPlace = null
      this.lat = null
      this.lng = null
    },
    async setPlace(place) {
      this.performingRequest = true
      this.lat = null
      this.lng = null
      this.currentPlace = place
        if (this.currentPlace) {
          this.lat = this.currentPlace.geometry.location.lat(),
            this.lng = this.currentPlace.geometry.location.lng()
          // this.marker = {position: marker}
          // this.place = this.currentPlace
          // // this.userProfile.center = marker;
          // this.currentPlace = null;
        }
        // if (place.geometry && place.geometry.viewport && place.geometry.viewport.Ja) {
        //   this.lng = place.geometry.viewport.Ja.hi || null
        //   this.lat = place.geometry.viewport.Va.hi || null
        // }
        // if (place.address_components) {
        //   this.performingRequest = true
        //   this.userProfile.address = {}
        //   setTimeout(() => {
        //       this.performingRequest = false
        //   }, 1000)
        //   console.log(this.userProfile.address)
        //   var components = place.address_components;
        //   for (var i = 0, component; component = components[i]; i++) {
        //     if (component.types[0] == 'street_number') {
        //         this.userProfile.address.street_number = component['long_name'];
        //     }
        //     if (component.types[0] == 'route') {
        //         this.userProfile.address.street = component['long_name'];
        //     }
        //     if (component.types[0] == 'locality') {
        //         this.userProfile.address.city = component['long_name'];
        //     }
        //     if (component.types[0] == 'administrative_area_level_1') {
        //         this.userProfile.address.state = component['short_name'];
        //     }
        //     if (component.types[0] == 'country') {
        //         this.userProfile.address.country = component['short_name'];
        //     }
        //     if (component.types[0] == 'postal_code') {
        //         this.userProfile.address.zip = component['short_name'];
        //     }
        //   }
        // }

      // this.updateProfile()
      // this.place = ''
      let coords = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      }
      // this.$emit('clicked', this.valid)
      this.$store.dispatch("getUsersByProximity", {
        coords: coords,
        distance: this.distance
      })
    },
    async updateSkills() {
      await this.$store.dispatch("clearUsersByProximity")
      let coords = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      }
      this.$store.dispatch("getUsersByProximity", {
        coords: coords,
        skills: this.filteredSkills,
        distance: this.distance
      })
    },
    updateDistance() {
      let coords = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      }
      this.$store.dispatch("getUsersByProximity", {
        coords: coords,
        skills: this.filteredSkills,
        distance: this.distance
      })
    },
    
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Points",
        "Rating",
        "Skills",
        "City",
        "State"
      ]
      const exportItems = [];
      for (var key in this.batch) {
        exportItems.push([
          this.batch[key].firstName,
          this.batch[key].lastName,
          this.batch[key].email,
          this.batch[key].phone,
          this.batch[key].points,
          this.batch[key].rating,
          this.batch[key].skills,
          this.batch[key].address.city,
          this.batch[key].address.state,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },

    // exportUsers() {
    //   const exportHeaders = [
    //     "Created",
    //     "First Name",
    //     "Middle Name",
    //     "Last Name",
    //     "Phone",
    //     "Email",
    //   ]
    //   const exportItems = [];
    //   for (var key in this.users) {

    //     let created = (this.formatDate(this.users[key].created) || null)

    //     exportItems.push([
    //       created,
    //       this.users[key].firstName,
    //       '',
    //       this.users[key].lastName,
    //       this.users[key].phone,
    //       this.users[key].email,
    //     ])
    //   }
    //   this.$gapi.getGapiClient().then(gapi => {
    //     const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
    //     exportService.export();
    //   });
    // },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.newGroup = ''
    delete this.newGroup
    this.textMessage = ''
    delete this.textMessage
    this.performingRequest = ''
    delete this.performingRequest
    this.performingRequest2 = ''
    delete this.performingRequest2
    this.performingRequest3 = ''
    delete this.performingRequest3
    this.isShowDistanceFilter = ''
    delete this.isShowDistanceFilter
    this.distance = ''
    delete this.distance
    this.filteredSkills = ''
    delete this.filteredSkills
    this.searchClient = ''
    delete this.searchClient
    this.batch = ''
    delete this.batch
    this.showGroupBox = ''
    delete this.showGroupBox
    this.showTextBox = ''
    delete this.showTextBox
    this.place = ''
    delete this.place
    this.lat = ''
    delete this.lat
    this.lng = ''
    delete this.lng
    this.columns = ''
    delete this.columns
    this.$store.dispatch('clearGroups')
    this.$store.dispatch('clearSkills')
    this.$store.dispatch('clearEmployees')
    this.$store.dispatch('clearUsersState')
    this.$store.dispatch("clearUsersByProximity")
  }
}
</script>